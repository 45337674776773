/*!

=========================================================
* Material Kit PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.8.0";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
// sections of this Page
import SectionFooter from "views/AnoproPage/Sections/SectionFooter";
import SectionPreFooter from "views/AnoproPage/Sections/SectionPreFooter";

// pages for this product
//20200901 リリース
import ServicePage1 from "views/ServicePage1/ServicePage1.js";
import ContactPage from "views/ContactPage/ContactPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import PolicyPage from "views/PolicyPage/PolicyPage.js";

// import AnoproPage from "views/AnoproPage/AnoproPage.js";
// import MessagePage from "views/MessagePage/MessagePage.js";
// import ColumnsAll from "views/ColumnsAll/ColumnsAll.js";
// import ColumnsProcess from "views/ColumnsProcess/ColumnsProcess.js";
// import ColumnPage from "views/ColumnPage/ColumnPage.js";
// import Post from "views/ColumnPage/Post.js";

var hist = createBrowserHistory();
const App = () => (
  <Router history={hist}>
    <div>
      <Header
        color="transparent"
        brand="Enjoy Crossover　アナプロ"
        links={<HeaderLinks dropdownHoverColor="primary" />}
        fixed
        changeColorOnScroll={{
          height: 150,
          color: "primary"
        }}
      // {...rest}
      />
      <main>
        <Switch>

          {/* <Route path="/message" component={MessagePage} />
          <Route path="/columns-all" component={ColumnsAll} />
          <Route path="/columns-process" component={ColumnsProcess} />
          <Route path="/post/:slug" component={Post} /> */}
          <Route path="/contact" component={ContactPage} />
          <Route path="/profile" component={ProfilePage} />
          <Route path="/policy" component={PolicyPage} />
          <Route path="/" component={ServicePage1} />
          {/* <Route path="/" component={AnoproPage} /> */}
        </Switch>
      </main>
      {/* <SectionPreFooter /> */}
      <SectionFooter />
    </div>
  </Router>
)
export default App
