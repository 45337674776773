import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import contentSectionStyle from "assets/jss/material-kit-pro-react/anopro/contentSectionStyle.js";

import idea from "assets/img/anopro/idea.png";
import hcd from "assets/img/anopro/hcd.png";
import think from "assets/img/anopro/think.png";
import game2 from "assets/img/anopro/game2.png";
import globaldesign from "assets/img/anopro/globaldesign.png";
import anarogy from "assets/img/anopro/anarogy.png";
import workshop from "assets/img/anopro/workshop.jpeg";

const useStyles = makeStyles(contentSectionStyle);

export default function SectionContents() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div
        className={classes.features5}
        style={{ backgroundImage: `url(${workshop})` }}
      >
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h4 className={classes.description}>― CONTENTS －</h4>
            <h2 className={classes.title}>研修で扱うコンテンツの一部</h2>
            <div className={classes.space}/>
          </GridItem>
          <div className={classes.container}>
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <Card plain >
                  <CardHeader image plain noShadow>
                    <img
                      className={classes.imgicon2}
                      src={idea}
                      alt="idea"
                    />
                  </CardHeader>
                  <CardBody plain>
                    <h4 className={classes.cardTitle3}>
                      アイデア創発
                    </h4>
                    <div
                      className={classes.description3 + " " + classes.marginTop20}
                    >
                    アイデア創発とはアイデアを量産する活動。<br/>ストーリーからアイデアを創る、アイデアからストーリーを創る方法を体感します。
                  </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <Card plain >
                  <CardHeader image plain noShadow>
                    <img
                      className={classes.imgicon2}
                      src={hcd}
                      alt="hcd"
                    />
                  </CardHeader>
                  <CardBody plain>
                    <h4 className={classes.cardTitle3}>
                      ユーザビリティ
                    </h4>
                    <div
                      className={classes.description3 + " " + classes.marginTop20}
                    >
                      モノづくりの基本はユーザビリティから。感性とユーザビリティの関係性について学びます。
                  </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <Card plain >
                  <CardHeader image plain noShadow>
                    <img
                      className={classes.imgicon2}
                      src={think}
                      alt="think"
                    />
                  </CardHeader>
                  <CardBody plain>
                    <h4 className={classes.cardTitle3}>
                    デザイン思考など
                    </h4>
                    <div
                      className={classes.description3 + " " + classes.marginTop20}
                    >
                      次々に紹介される〇〇思考に右往左往しないために、デザインの体幹を強くし、〇〇思考との向き合い方について考えます。
                  </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <Card plain >
                  <CardHeader image plain noShadow>
                    <img
                      className={classes.imgicon2}
                      src={game2}
                      alt="game"
                    />
                  </CardHeader>
                  <CardBody plain>
                    <h4 className={classes.cardTitle3}>
                      モチベーション設計
                    </h4>
                    <div
                      className={classes.description3 + " " + classes.marginTop20}
                    >
                      優れたデザインは人々に自発的な行動を促します。ゲーミフィケーション等のテクニカルな観点にも触れながらデザインを起点とした良好なモチベーション設計について学びます。
                  </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <Card plain >
                  <CardHeader image plain noShadow>
                    <img
                      className={classes.imgicon2}
                      src={globaldesign}
                      alt="globaldesign"
                    />
                  </CardHeader>
                  <CardBody plain>
                    <h4 className={classes.cardTitle3}>
                      デザインの拡張
                    </h4>
                    <div
                      className={classes.description3 + " " + classes.marginTop20}
                    >
                      人間中心のモノづくりだけでは立ちゆかない時代を迎えています。SDGsやスパキュラティブデザインといったアプローチにも触れながらデザインの拡張について考えます。
                  </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <Card plain >
                  <CardHeader image plain noShadow>
                    <img
                      className={classes.imgicon2}
                      src={anarogy}
                      alt="anarogy"
                    />
                  </CardHeader>
                  <CardBody plain>
                    <h4 className={classes.cardTitle3}>
                      アナロジー
                    </h4>
                    <div
                      className={classes.description3 + " " + classes.marginTop20}
                    >
                      クリエイティブな発想と関係の深いアナロジーに着目し、特徴の理解と実践に向けてトレーニングを行います。
                  </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </GridContainer>
      </div>
    </div>
  );
}
