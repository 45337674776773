import {
  title,
  description,
  section,
  container,
  mlAuto,
  mrAuto,
  cardTitle,
  card,
  dangerColor,
  successColor,
  blackColor,
  grayColor,
  whiteColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";

const priceSectionStyle = {
  container,
  mlAuto,
  mrAuto,
  cardTitle,
  description: {
    ...description,
    fontSize: "15px", //add
    lineHeight: "2em",
    color: blackColor,
  },
  title: {
    ...title,
    marginBottom: "30px", // 5->30
    marginTop: "5px", //60->5
    "& + $description": {
      marginTop: "10px", //5->10
      marginBottom: "5px" //30->5
    }
  },
  section: {
    ...section,
    padding: "50px 0px",
    "& h4$description": {
      fontSize: "1.5em",
      color: grayColor[1]
    }
  },
  buttontext:{
    padding:"0 0 0 0",
    fontSize: "12px",
    color: whiteColor,
    "&,&:focus,&:hover": {
      // backgroundColor: whiteColor,
      color: whiteColor
    }
  },
  // iconGithub: {
  //   fontSize: "82px",
  //   color: grayColor[10]
  // },
  // iframeGithub: {
  //   top: "11px",
  //   display: "inline-block",
  //   position: "relative",
  //   marginLeft: "10px"
  // },
  card: {
    ...card,
    border: "0px",
    marginBottom: "30px",
    marginTop: "30px",
    borderRadius: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ",0.87)",
    width: "100%",
    "& ul": {
      listStyle: "none",
      padding: "0px",
      maxWidth: "240px",
      margin: "10px auto"
    },
    "& ul li": {
      color: grayColor[1],
      padding: "12px 0px",
      borderBottom: "1px solid rgba(" + hexToRgb(grayColor[0]) + ",0.3)",
      textAlign: "left"
    },
    "& ul li b": {
      minWidth: "24px",
      display: "inline-block",
      textAlign: "center",
      color: grayColor[1]
    }
  },
  cardPricing: {
    boxShadow: "none"
  },
  cardIcons: {
    top: "6px",
    position: "relative"
  },
  dangerColor: {
    color: dangerColor[0]
  },
  successColor: {
    color: successColor[0]
  }
};

export default priceSectionStyle;
