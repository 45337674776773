import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardFooter from "components/Card/CardFooter.js";
// import CardAvatar from "components/Card/CardAvatar.js";
// import Info from "components/Typography/Info.js";
// import Danger from "components/Typography/Danger.js";
// import Success from "components/Typography/Success.js";
// import Warning from "components/Typography/Warning.js";
// import Rose from "components/Typography/Rose.js";
// import Button from "components/CustomButtons/Button.js";

import faqsectionstyle from "assets/jss/material-kit-pro-react/anopro/faqsectionstyle.js";

const useStyles = makeStyles(faqsectionstyle);

export default function SectionFAQ() {
  const [activeRotate1, setActiveRotate1] = React.useState("");
  const [activeRotate2, setActiveRotate2] = React.useState("");
  const [activeRotate3, setActiveRotate3] = React.useState("");
  const classes = useStyles();
  React.useEffect(() => {
    if (window) {
      window.addEventListener("resize", addStylesForRotatingCards);
    }
    addStylesForRotatingCards();
    return function cleanup() {
      if (window) {
        window.removeEventListener("resize", addStylesForRotatingCards);
      }
    };
  });
  const addStylesForRotatingCards = () => {
    var rotatingCards = document.getElementsByClassName(classes.cardRotate);
    // for (let i = 0; i < rotatingCards.length; i++) {
    //   var rotatingCard = rotatingCards[i];
    //   var cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
    //   var cardBack = rotatingCard.getElementsByClassName(classes.back)[0];
    //   cardFront.style.height = "unset";
    //   cardFront.style.width = "unset";
    //   cardBack.style.height = "unset";
    //   cardBack.style.width = "unset";
    //   var rotatingWrapper = rotatingCard.parentElement;
    //   var cardWidth = rotatingCard.parentElement.offsetWidth;
    //   var cardHeight = rotatingCard.children[0].children[0].offsetHeight;
    //   rotatingWrapper.style.height = cardHeight + "px";
    //   rotatingWrapper.style["margin-bottom"] = 30 + "px";
    //   cardFront.style.height = "unset";
    //   cardFront.style.width = "unset";
    //   cardBack.style.height = "unset";
    //   cardBack.style.width = "unset";
    //   cardFront.style.height = cardHeight + 35 + "px";
    //   cardFront.style.width = cardWidth + "px";
    //   cardBack.style.height = cardHeight + 35 + "px";
    //   cardBack.style.width = cardWidth + "px";
    // }
  };
  return (
    <div className="cd-section" id="cards">
      <div className={classes.sectionGray}>
        <div>
          {/* BLOG CARDS START */}
          <div>
            <div className={classes.container}>
              <h4 className={classes.description}>― FAQ －</h4>
              <h2 className={classes.title}>よくあるお問い合わせ</h2>  
              <div className={classes.space} />
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={4}>
                  <Card blog>
                    <CardBody>
                      <h4 className={classes.cardTitle}>
                        Ｑ.<br/>デザイン思考の研修とは異なりますか？
                      </h4>
                      <p className={classes.cardDescription}>
                        Ａ.はい、異なります。デザイン思考によくあるユーザー分析やリサーチ手法の講座ではございません（一部含まれます）。本講座は思考よりも感性にフォーカスしたワークが中心です。テクニカルな方法を活かすために『デザインする』とはどういうことかに向きあって頂きます。
                      </p>
                    </CardBody>
                  </Card>
                  <div className={classes.space} />
                  <Card blog>
                    <CardBody>
                      <h4 className={classes.cardTitle}>
                        Ｑ.<br/>デザインツールのスキルやスケッチ力は身に付きますか？
                      </h4>
                      <p className={classes.cardDescription}>
                        Ａ.本講座ではデザインツールやスケッチは扱いません。別途e-leaningコースがありますのでそちらをご受講いただけます。
                      </p>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={4}>
                <Card blog>
                    <CardBody>
                      <h4 className={classes.cardTitle}>
                        Ｑ.<br/>研修内容はカスタマイズ可能でしょうか？
                      </h4>
                      <p className={classes.cardDescription}>
                        Ａ.はい、可能です。本講座はトレーニングが目的のため、トレーニング内容そのものを変えることは出来ませんが、後半で行うデザインの実践をオリジナルのテーマで行うことは可能です。
                      </p>
                    </CardBody>
                  </Card>
                  <div className={classes.space} />
                  <Card blog>
                    <CardBody>
                      <h4 className={classes.cardTitle}>
                        Ｑ.<br/>造形力（スタイリング・カラー）についての研修ですか？
                      </h4>
                      <p className={classes.cardDescription}>
                        Ａ.本講座では造形やトレンドについて言及するものではございません。製品・サービスを創る上で、魅力的なコンセプトをつくるためのデザイン基礎力を身に付ける研修です。
                      </p>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={4}>
                  <Card blog>
                    <CardBody>
                      <h4 className={classes.cardTitle}>
                        Ｑ.<br/>WEBデザインやIT系の研修ですか？
                      </h4>
                      <p className={classes.cardDescription}>
                        Ａ.題材としてはプロダクトデザイン（工業製品）やサービスデザインが中心となります。本研修はデザインの素養・基礎力を身に付ける事が目的のため、業界問わず適用可能です。
                      </p>
                    </CardBody>
                  </Card>
                  <div className={classes.space} />
                  <Card blog>
                    <CardBody>
                      <h4 className={classes.cardTitle}>
                        Ｑ.<br/>開発イベントに使えますか？
                      </h4>
                      <p className={classes.cardDescription}>
                        Ａ.本研修はデザインマインドを構築するためのトレーニングが目的のため、開発イベントには適用出来ません。ただし、後半プログラムで参加者それぞれにデザインを考えて頂く際に、オリジナルのテーマで実施することは可能です。
                      </p>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </div>
          {/* BLOG CARDS END */}
          <div className={classes.space} />
        </div>
      </div>
    </div>
  );
}
