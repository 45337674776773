/*!

=========================================================
* Material Kit PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.8.0";

//apolloclient comp
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from 'react-apollo'

//Firebase
import Firebase, { FirebaseContext } from './Firebase';
//coer comp
import App from 'views/App.js';

import registerServiceWorker from './registerServiceWorker'

// Replace this with your project's endpoint
// const GRAPHCMS_API = 'https://api-apeast.graphcms.com/v1/ck31ogc8f2z4a01hx0s5c5tfm/master'
//自分んのエンドポイント追加
// https://api-apeast.graphcms.com/v1/ck31ogc8f2z4a01hx0s5c5tfm/master
const GRAPHCMS_API = process.env.REACT_APP_GRAPHCMS_ENDPOINT;

const client = new ApolloClient({
  link: new HttpLink({ uri: GRAPHCMS_API }),
  cache: new InMemoryCache()
})

// var hist = createBrowserHistory();

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>,
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
registerServiceWorker()