import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import featureSectionStyle from "assets/jss/material-kit-pro-react/anopro/featureSectionStyle.js";
import Check from "@material-ui/icons/Check";
import mind from "assets/img/anopro/mind.png";
import vision from "assets/img/anopro/vision.png";

const useStyles = makeStyles(featureSectionStyle);

export default function SectionFeaturers() {
  const classes = useStyles();
  return (
    <div className={classes.sectionGray}>
      <div className={classes.container}>
        <div className={classes.features}>
          <GridContainer justify="center">
            <GridItem sm={12} xs={12} md={6} lg={6}>
            <h4 className={classes.description}>
              － FEATURER －
            </h4>
            <h2 className={classes.title}>思考する前にマインドを育てる</h2>
            <h5 className={classes.description}>
              デザインのスタートは思考するものではなく感じるもの。<br/>本研修では、感性にフォーカスしたトレーニングを通して、手とココロを動かしながらモノゴトに対する論理的なアプローチと感性的なアプローチの違いを理解し、その両輪を使って『デザインする』とはどういうことか向き合います。<br/>
              デザイン教育を受けていない組織やメンバーがデザイン活動を始めるときに陥ってしまう失敗の多くは、初めからテクニカルな方法を用いて思考=分析してしまうことです。まずは感じるココロを鍛え、デザイン活動の体幹をとなる、『デザインマインド』を構築することを目指します。
            </h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6} className={classes.mlAuto}>
              <div className={classes.imageContainer}>
                <img
                  src={mind}
                  alt="mind"
                // className={classes.componentsMacbook}
                />
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.features}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <div className={classes.imageContainer}>
                <img src={vision}
                  alt="vision"
                // className={classes.componentsMacbook}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <h4 className={classes.description}>― VISION ―</h4>
              <h2 className={classes.title}>クリエイティブ人材の一歩</h2>
              <h5 className={classes.description}>
                本研修と通してデザインマインドを構築することで、さまざまな角度から価値に向き合い、ユニークなアイデアを実現するクリエイティブ人材の第一歩を創出します。
              </h5>
              <InfoArea
                className={classes.infoArea}
                icon={Check}
                title="論理と感性の両輪でモノゴトに向きあえる"
                description="オペレーション業務の枠にとらわれないスケールの大きい視点でモノゴトに向き合い、クリエイティブな活動をリードする存在へ"
                iconColor="primary"
              />
              <InfoArea
                className={classes.infoArea}
                icon={Check}
                title="コンセプトチャルな提案にこだわる"
                description="ありきたりな提案を回避し、ユニークで心に響く提案の追及にこだわる人材へ"
                iconColor="primary"
              />
              <InfoArea
                className={classes.infoArea}
                icon={Check}
                title="世の中を幸せにする"
                description="デザインの本質はヒト・モノ・コトの良好な関係を追及すること。優れたデザインの追及は世の中を幸せにします"
                iconColor="primary"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
