/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component used to create nice image meadia player
// import ImageGallery from "react-image-gallery";
// react component for creating beautiful carousel
// import Carousel from "react-slick"; //add
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import LocalShipping from "@material-ui/icons/LocalShipping";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Favorite from "@material-ui/icons/Favorite";
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Accordion from "components/Accordion/Accordion.js";
// import Card from "components/Card/Card.js";
// import Footer from "components/Footer/Footer.js";
// import Button from "components/CustomButtons/Button.js";

import timelineSectionStyle from "assets/jss/material-kit-pro-react/anopro/timelineSectionStyle.js";

// images
import sketch from "assets/img/anopro/sketch.jpg";
import jio from "assets/img/anopro/jio.jpg";
import story from "assets/img/anopro/story.jpeg";

const useStyles = makeStyles(timelineSectionStyle);

export default function SectionTimeline() {
  const [colorSelect, setColorSelect] = React.useState("0");
  const [sizeSelect, setSizeSelect] = React.useState("0");
  const classes = useStyles();
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery); //タイル表示に必要
  // const images = [
  //   {
  //     original: product3,
  //     thumbnail: product3
  //   },
  //   {
  //     original: product4,
  //     thumbnail: product4
  //   },
  //   {
  //     original: product1,
  //     thumbnail: product1
  //   },
  //   {
  //     original: product2,
  //     thumbnail: product2
  //   }
  // ];
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 1000,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true
  // };
  return (
    <div className={classes.section}>
      <div className={classes.productPage}>
        <div className={classNames(classes.section, classes.sectionGray)}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem md={6} sm={6}>
                <h4 className={classes.description}>
                  － PROGRAM －
                </h4>
                <h2 className={classes.title}>プログラム</h2>
                <h5 className={classes.description}>
                  定型的な研修の流れをご紹介します。手とココロを動かし、テクニカルな方法も交えながら、『デザインマインド』を構築します。
                  <br />※プログラムは変更することがございます
                </h5>
                <Accordion
                  active={0}
                  activeColor="rose"
                  collapses={[
                    {
                      title: "01　デザインに向き合う",
                      content: (
                        <p>
                          デザインという言葉は抽象的なものです。「デザインとは何か？」という答えのない問いについて、事例を踏まえながらワークショップ形式で向き合います。
                        </p>
                      )
                    },
                    {
                      title: "02　クリエイティブエクササイズ",
                      content: (
                        <p>
                          デザインの実践に向けたエクササイズを行います。<br />
                          観察力・発想力・展開力・表現力などを題材に、感性でモノゴトに向き合い、時に楽しく、時に生みの苦しみを味わいながら、私たちが本来持っているクリエイティブな感覚を呼び起こします。
                        </p>
                      )
                    },
                    {
                      title: "03　デザインの実践",
                      content: (
                        <p>
                          クリエイティブな感覚だけではデザインに繋げることはできません。論理や感性といったアプローチはデザインに向き合うための手段に過ぎず、その本質は別にあります。デザインの実践では具体的なテーマをもとに、論理や感性を駆使してデザインの本質に向き合います。
                        </p>
                      )
                    },
                    {
                      title: "04　拡張デザイン",
                      content: (
                        <p>
                          私たちを取り巻く生活環境は必ずしも良好とは言えない時代に直面しています。人間中心のモノづくりだけでは立ち行かない状況の中で、これからの時代に必要となってくるデザインアプローチについて考察します。
                        </p>
                      )
                    },
                    {
                      title: "05　デザインチャレンジ（実習）",
                      content: (
                        <p>
                          研修で学んだ内容を総動員して、テーマに沿ったデザイン提案を行って頂きます。
                        </p>
                      )
                    }
                  ]}
                />
              </GridItem>
              {/* スペース用 */}
              <GridItem xs={12} sm={12} md={1} />
              {/* タイル表示 */}
              <GridItem xs={12} sm={12} md={5}  className={classes.navImage}>
                <img
                  alt="..."
                  src={sketch}
                  className={navImageClasses}
                />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <img
                      alt="..."
                      src={jio}
                      className={navImageClasses}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <img
                      alt="..."
                      src={story}
                      className={navImageClasses}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>

              {/* <GridItem md={6} sm={6}>
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  startIndex={1}
                  items={images}
                />
              </GridItem> */}

              {/* Carousel */}
              {/* <GridItem xs={12} sm={6} md={5} className={classes.marginAuto}>
                <Card>
                  <Carousel {...settings}>
                    <div>
                      <img src={sketch} alt="First slide" className="slick-image" />
                      <div className="slick-caption">
                        <h4>
                          <LocationOn className="slick-icons" />
                          Yellowstone National Park, United States
                        </h4>
                      </div>
                    </div>
                    <div>
                      <img
                        src={jio}
                        alt="Second slide"
                        className="slick-image"
                      />
                      <div className="slick-caption">
                        <h4>
                          <LocationOn className="slick-icons" />Somewhere Beyond, United States
                        </h4>
                      </div>
                    </div>
                    <div>
                      <img src={story} alt="Third slide" className="slick-image" />
                      <div className="slick-caption">
                        <h4>
                          <LocationOn className="slick-icons" />
                          Yellowstone National Park, United States
                        </h4>
                      </div>
                    </div>
                  </Carousel>
                </Card>
              </GridItem> */}
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
