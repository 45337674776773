import {
  container,
  mlAuto,
  mrAuto,
  title,
  cardTitle,
  description,
  sectionDark,
  section,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";

const contentSectionStyle = {
  container,
  mlAuto,
  mrAuto,
  // cardTitle,
  description,
  sectionDark,
  section: {
    ...section,
    padding: "50px 0px",
    "& h4$description": {
      fontSize: "1.5em"
    }
  },
  title: {
    ...title,
    textAlign: "center",
    marginBottom: "30px", // 5->30
    marginTop: "5px", //60->5
    "& + $description": {
      marginTop: "10px", //5->10
      marginBottom: "5px" //30->5
    }
  },
  // features1: {
  //   textAlign: "center",
  //   padding: "80px 0"
  // },
  // features2: {
  //   padding: "80px 0"
  // },
  // features3: {
  //   padding: "80px 0",
  //   "& $phoneContainer": {
  //     maxWidth: "none",
  //     margin: "0 auto"
  //   }
  // },
  // features4: {
  //   padding: "80px 0",
  //   "& $phoneContainer": {
  //     maxWidth: "260px",
  //     margin: "60px auto 0"
  //   }
  // },
  features5: {
    padding: "80px 0",
    backgroundSize: "cover", //contain,cover
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    position: "relative",
    "& $title": {
      marginBottom: "30px"
    },
    "& $title,& $container,& h4": {
      position: "relative",
      zIndex: "2",
      marginBottom: "5px",
      color: whiteColor
    },
    "&:after": {
      background: "rgba(" + hexToRgb(blackColor) + ",0.55)",//0.55
      position: "absolute",
      width: "100%",
      height: "100%",
      content: "''",
      zIndex: "0",
      left: "0px",
      top: "0px"
    },
    "& $container": {
      "& $gridContainer:last-child": {
        "& $gridItem": {
          borderBottom: "0"
        }
      },
      "& $gridItem": {
        border: "1px solid rgba(" + hexToRgb(whiteColor) + ", 0.35)",
        borderTop: "0",
        borderLeft: "0",
        "&:last-child": {
          borderRight: "0"
        }
      }
    },
    // "& $infoArea5": {
    //   textAlign: "center",
    //   maxWidth: "310px",
    //   minHeight: "320px",
    //   "& h4,& p,& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
    //     color: whiteColor
    //   }
    // }
  },
  // features6: {
  //   padding: "80px 0",
  //   backgroundSize: "cover", //contain,cover
  //   backgroundPosition: "50%",
  //   backgroundRepeat: "no-repeat",
  //   position: "relative",
  //   "& $title": {
  //     marginBottom: "30px"
  //   },
  //   "& $title,& $container": {
  //     position: "relative",
  //     zIndex: "2",
  //     color: whiteColor
  //   },
  //   "&:after": {
  //     background: "rgba(" + hexToRgb(blackColor) + ",0.3)",
  //     position: "absolute",
  //     width: "100%",
  //     height: "100%",
  //     content: "''",
  //     zIndex: "0",
  //     left: "0px",
  //     top: "0px"
  //   },
  //   "& $container": {
  //     "& $gridContainer:last-child": {
  //       "& $gridItem": {
  //         borderBottom: "0"
  //       }
  //     },
  //     "& $gridItem": {
  //       border: "1px solid rgba(" + hexToRgb(whiteColor) + ", 0.35)",
  //       borderTop: "0",
  //       borderLeft: "0",
  //       "&:last-child": {
  //         borderRight: "0"
  //       }
  //     }
  //   },
  //   "& $infoArea": {
  //     // textAlign: "center",
  //     // maxWidth: "310px",
  //     // minHeight: "320px",
  //     "& h4,& h6,& p,& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
  //       color: whiteColor
  //     }
  //   }
  // },
  gridContainer: {},
  gridItem: {},
  textCenter: {
    textAlign: "center"
  },
  // phoneContainer: {
  //   "& img": {
  //     width: "100%",
  //   }
  // },
  // infoArea: {
  //   maxWidth: "none",
  //   margin: "0 auto",
  //   padding: "10px 0 0px"
  // },
  // infoArea5: {},
  // imgicon: {
  //   padding: "10px 100px 10px 100px"
  // },
  imgicon2: {
    padding: "0px 50px 0px 50px"
  },
  // cardTitle2: {
  //   "&, & a": {
  //     ...title,
  //     marginTop: "0rem",
  //     marginBottom: "0.75rem",
  //     minHeight: "auto"
  //   }
  // },
  cardTitle3: {
    "&, & a": {
      ...title,
      color: whiteColor,
      marginTop: "0rem",
      marginBottom: "0.75rem",
      minHeight: "auto",
      textAlign: "center"
    }
  },
  // description2: {
  //   color: grayColor[4], //0->4
  //   overflow: "hidden",
  //   marginTop: "0px",
  //   fontSize: "16px",
  // },
  description3: {
    color: whiteColor,
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "14px",
    padding: "0px 20px",
    // textAlign: "center"
  },
  space:{
    marginTop: "60px",
  },
};

export default contentSectionStyle;
