/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page
import SectionAbout from "views/ServicePage1/Sections/SectionAbout.js";
import SectionFeaturers from "views/ServicePage1/Sections/SectionFeaturers.js";
import SectionContents from "views/ServicePage1/Sections/SectionContents.js";
import SectionTimeline from "views/ServicePage1/Sections/SectionTimeline.js";
import SectionPrice from "views/ServicePage1/Sections/SectionPrice.js";
import SectionFAQ from "views/ServicePage1/Sections/SectionFAQ.js";

import servicePage1Style from "assets/jss/material-kit-pro-react/anopro/servicePage1Style.js";

const useStyles = makeStyles(servicePage1Style);

export default function ServicePage1() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax
        image={require("assets/img/anopro/hand.jpeg")}
        className={classes.parallax}
        // filter="dark"
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1>
                  デザインマインド<br/>ブートキャンプ
                  {/* <span className={classes.proBadge}>PRO</span> */}
                </h1>
                <h4 className={classes.title}>
                  新商品開発・イノベーション開発を担う組織・チームメンバーにデザインマインドを構築
                </h4>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main)}>
        <SectionAbout />
        <SectionFeaturers />
        <SectionTimeline />
        <SectionContents />
        <SectionPrice />
        <SectionFAQ />
        {/* <SectionOverview /> */}
      </div>
    </div>
  );
}
