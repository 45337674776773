import {
  container,
  cardTitle,
  title,
  mlAuto,
  mrAuto,
  main,
  whiteColor,
  mainRaised,
  grayColor,
  blackColor
} from "assets/jss/material-kit-pro-react.js";

import imagesStyle from "assets/jss/material-kit-pro-react/imagesStyles.js";

import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const policyPageStyle = {
  container:{
    ...container,
    paddingBottom: "100px",
  },
  ...imagesStyle,
  ...tooltipsStyle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + "  !important"
  },
  cardTitle,
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  description: {
    margin: "40px auto 0",
    // margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: blackColor,
    lineHeight: "1.8em",
    "& h3": {
      color: grayColor[1],
      fontSize :"24px",
      fontWeight: "550",
      lineHeight: "1.8em",
    },
    "& h5": {
      color: blackColor,
      fontSize :"14px",
      lineHeight: "1.8em",
    }
  },
  textCenter: {
    textAlign: "center !important"
  },
  main: {
    ...main,
  },
  title: {
    ...title,
    position: "relative",
    marginTop: "80px",
    marginBottom: "0px",
    // minHeight: "32px",
    textAlign: "center",
    // textDecoration: "none"
  },
  listOnstyled: {
    paddingLeft: "30px",
    // listStyle: "none",
    "& > li": {
      padding: "5px 0px",
      fontSize: "14px",
      lineHeight: "1.5em"
    }
  },
  parallax: {
    height: "100px",
    // backgroundPosition: "top center"
    backgroundPosition: "0% 50%"
  }
};

export default policyPageStyle;
