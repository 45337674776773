import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import InfoArea from "components/InfoArea/InfoArea.js";

// @material-ui icons
// import Apps from "@material-ui/icons/Apps";
// import ViewDay from "@material-ui/icons/ViewDay";
// import ViewCarousel from "@material-ui/icons/ViewCarousel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import aboutSectionStyle from "assets/jss/material-kit-pro-react/anopro/aboutSectionStyle.js";

import hub from "assets/img/anopro/openinv.png";
import de from "assets/img/anopro/de.png";
import inv3 from "assets/img/anopro/inv3.png";

const useStyles = makeStyles(aboutSectionStyle);

export default function SectionAbout() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center" >
          <GridItem md={8} sm={8}>
            <h4 className={classes.description}>－ ABOUT －</h4>
            <h2 className={classes.title}>ノンデザイナー向けデザイン研修</h2>
            <h5 className={classes.description}>
              変化の激しい時代に直面し、企業・組織・エンジニアが一つの専門分野だけに取り組んでいればうまくいく時代は終わりを迎えています。新しい技術や畑違いの価値観に向き合うために、自身の専門分野と他分野をつなげ、新しい価値を生み出すためにデザイン活動を取り入れることは非常に効果的です。
              なぜならモノゴトの関係性を再構築し、新たな価値を模索する行為とデザインは切っても切れない関係にあるからです。
              <br/>デザインマインドブートキャンプは、新たな価値の探求にデザインを取り入れたい企業・組織・エンジニアのためのデザイン入門研修です。オペレーション業務で鍛えた論理的なアプローチに加え、感性的なアプローチを取り入れることで、ヒト・モノ・コトを新たなステージへ導きます。
            </h5>
          </GridItem>
        </GridContainer>
        <div className={classes.features}>
          <GridContainer>
            <GridItem md={4} sm={4}>
              <Card plain >
                <CardHeader image plain noShadow>
                  <img
                    className={classes.imgicon2}
                    src={inv3}
                    alt="..."
                  />
                </CardHeader>
                <CardBody plain>
                  <h4 className={classes.cardTitle2}>
                    イノベーション組織のメンバー育成
                  </h4>
                  <div
                    className={classes.description2 + " " + classes.marginTop20}
                  >
                    箱は作ったがやることが決まっていない。イノベーション組織のスタートダッシュをリードする、<br/>クリエイティブなメンバーを育てます。
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem md={4} sm={4}>
              <Card plain >
                <CardHeader image plain noShadow>
                  <img
                    className={classes.imgicon2}
                    src={hub}
                    alt="..."
                  />
                </CardHeader>
                <CardBody plain>
                  <h4 className={classes.cardTitle2}>
                    HUB人材の育成
                  </h4>
                  <div
                    className={classes.description2 + " " + classes.marginTop20}
                  >
                    ピースはそろっているのにアウトプットがいつもと変わらない・・・。バラバラのピースをつなげるだけでなく、時には足りないピースをつくる。モノ・コトを再構築できるHUB人材を育てます。
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem md={4} sm={4}>
              <Card plain >
                <CardHeader image plain noShadow>
                  <img
                    className={classes.imgicon2}
                    src={de}
                    alt="..."
                  />
                </CardHeader>
                <CardBody plain>
                  <h4 className={classes.cardTitle2}>
                    デザインエンジニア育成
                  </h4>
                  <div
                    className={classes.description2 + " " + classes.marginTop20}
                  >
                    機能や性能だけでなく感性にも目を向け、人のこころを動かす設計が出来る、デザインエンジニアを育成します。
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
