/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import People from "@material-ui/icons/People";
import Add from "@material-ui/icons/Add";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Badge from "components/Badge/Badge.js";
import Muted from "components/Typography/Muted.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";

import logo from "assets/img/anopro/logo2.jpg";
import prof from "assets/img/anopro/prof.jpg";

import profilePageStyle from "assets/jss/material-kit-pro-react/anopro/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function ProfilePage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Parallax
        image={require("assets/img/anopro/hand.jpeg")}
        // filter="dark"
        className={classes.parallax}
      />
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <div className={classes.profile}>
                <div>
                  <img src={logo} alt="logo" className={imageClasses} />
                </div>
                <div className={classes.name}>
                  <h3 className={classes.title} >ANOTHER PRODUCT</h3>
                  {/* <h4>sekiguchi daisuke</h4> */}

                  {/* <Button
                    justIcon
                    simple
                    color="dribbble"
                    className={classes.margin5}
                  >
                    <i className={classes.socials + " fab fa-dribbble"} />
                  </Button>
                  <Button
                    justIcon
                    simple
                    color="twitter"
                    className={classes.margin5}
                  >
                    <i className={classes.socials + " fab fa-twitter"} />
                  </Button>
                  <Button
                    justIcon
                    simple
                    color="pinterest"
                    className={classes.margin5}
                  >
                    <i className={classes.socials + " fab fa-pinterest"} />
                  </Button> */}
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <div className={classNames(classes.description)}>
            <h5>
              近年、多様な人材を集め、越境組織に取り組む企業や、社内外含め共創活動を実践する組織が増えています。
              自身の領域を超えるために、分野の異なる専門家を集める・共創活動を促すプロセスやファシリテーターを配置する・アート的な活動にも取り組んでみるなど、アプローチは様々です。
            <br />これまで多くのイノベーション組織や社内共創活動をサポートしてきた経験からお伝えしたい事の一つとして、次の事が挙げられます。それは、『良いアイデア・良いデザインは一人が創る』ということ。先に挙げた、共創環境やプロセス、ファシリテーションなどは補助的なモノでしかなく、最終的にクリエイティブな活動を引っ張るのは、自身の専門分野を持ちながら他分野の技術や価値観を自身の中に取り入れ、かみ砕き、うまく再構築する力を持った一人です。アナプロでは、デザインマインドの取り組みを通して、個人の持っているクリエイティブな力を育てることをコンセプトとしております。新しいステージにチャレンジする企業・組織・ビジネスパーソンにクリエイティブな学びの機会を作っています。
            </h5>
          </div>
          <div className={classes.profileTabs}>
            <NavPills
              alignCenter
              color="primary"
              tabs={[
                {
                  tabButton: "プロフィール",
                  tabIcon: Palette,
                  tabContent: (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={7} className={classes.gridItem}>
                        <Card profile plain className={classes.textLeft}>
                          <GridContainer>
                            <GridItem xs={12} sm={4} md={4}>
                              <CardHeader image plain>
                                {/* <img src={prof} alt="..." className={classes.imgRoundedCircle + " " + classes.imgFluid}/> */}
                                <img src={prof} alt="..." className={classes.imgRounded + " " + classes.imgFluid}/>
                              </CardHeader>
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8}>
                              <CardBody plain>
                                <h4 className={classes.cardTitle}>関口 大介</h4>
                                <h5 className={classes.cardTitlesub}>sekiguchi daisuke</h5>
                                <h6>代表/インストラクター</h6>
                                {/* <Muted>
                                </Muted> */}
                              </CardBody>
                            </GridItem>
                          </GridContainer>
                        </Card>
                        <h4 className={classes.title}>経歴</h4>
                        <GridContainer className={classes.collections}>
                          <p> 武蔵野美術大学大学院卒。公共空間ファニチャーメーカーにてプロダクトデザイナーとしてのキャリアをスタート。その後、産業用ロボットメーカーにてメカエンジニア・ソフトウェアエンジニアに従事、自動車Tier1メーカーにてプロダクトデザイナー・商品企画に従事する。
                          デザインとエンジニアングの領域をクロスオーバーしながら、モノづくりに携わる。
                          2018年より、イノベーションコンサルファームにて、大手企業向けに商品設計を目的とした共創プロセスやアイデア創発のサポートを行う。
                          また、自身の経験による複合スキルから生まれるアウトプットの可能性をモノづくり企業・エンジニアに伝え、イノベーティブでユニークな製品・サービスを世の中に増やしたいとの想いから、アナプロの活動を開始。</p>
                          <Table
                            tableHead={[
                              "#",
                              "企業",
                              "担当"
                            ]}
                            tableData={[
                              ["2004", "武蔵野美術大学大学院造形研究科デザイン 卒業", "インダストリアルデザイン"],
                              ["2004-", "公共空間ファニチャーメーカー", "プロダクトデザイナー"],
                              ["2006-", "産業用ロボットメーカー", "ロボットエンジニア（メカ・ソフト）"],
                              ["2016-", "自動車Tier1メーカー", "プロダクトデザイナー、商品企画"],
                              ["2018-", "イノベーションコンサルファーム", "プロマネ、ファシリテーター"],
                              ["2018-", "アナプロ設立", "代表"],
                            ]}
                            customCellClasses={[
                              classes.textCenter,
                              classes.textRight,
                              classes.textRight
                            ]}
                            customClassesForCells={[0, 4, 5]}
                            customHeadCellClasses={[
                              classes.textCenter,
                              classes.textRight,
                              classes.textRight
                            ]}
                            customHeadClassesForCells={[0, 4, 5]}
                          />
                        </GridContainer>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={3}
                        className={classes.gridItem}
                      >
                        {/* <h4 className={classes.title}>インストラクター</h4>
                        <h5 className={classes.description}>関口大介</h5> */}
                        {/* <ul className={classes.listUnstyled}>
                          <li>
                            ・感性工学
                          </li>
                          <li>
                            ・日本ゲーミフィケーション協会
                          </li>
                        </ul> */}
                        <hr />
                        <h4 className={classes.title}>活動範囲</h4>
                        <p className={classes.description}>
                          アナプロでは時代に合ったクリエイティブ人材育成に取り組んでいます。研修以外でも、ワークショップの企画、デザインプロセスの構築、PoCサポート、地方創生に向けた活動も行っています。お気軽にお問い合わせ下さい。
                        </p>
                        <hr />
                        <h4 className={classes.title}>#専門タグ</h4>
                        <Badge color="primary">#ロボット工学</Badge>
                        <Badge color="primary">#プロダクトデザイン</Badge>
                        <Badge color="primary">#POC</Badge>
                        <Badge color="primary">#ユーザビリティテスト</Badge>
                        <Badge color="primary">#共創デザイン</Badge>
                        <Badge color="primary">#ワークショップデザイナー</Badge>
                      </GridItem>
                    </GridContainer>
                  )
                },
                // {
                //   tabButton: "パートナー",
                //   tabIcon: People,
                //   tabContent: (
                //     <div>
                //       <GridContainer justify="center">
                //         <GridItem
                //           xs={12}
                //           sm={12}
                //           md={5}
                //           className={classes.gridItem}
                //         >
                //           <Card profile plain className={classes.card}>
                //             <GridContainer>
                //               <GridItem xs={12} sm={12} md={5}>
                //                 <CardHeader image plain>
                //                   <a href="#pablo">
                //                     <img src={avatar} alt="..." />
                //                   </a>
                //                   <div
                //                     className={classes.coloredShadow}
                //                     style={{
                //                       backgroundImage: "url(" + avatar + ")",
                //                       opacity: "1"
                //                     }}
                //                   />
                //                 </CardHeader>
                //               </GridItem>
                //               <GridItem xs={12} sm={12} md={7}>
                //                 <CardBody plain>
                //                   <h4 className={classes.cardTitle}>
                //                     株式会社HackCamp
                //                   </h4>
                //                   <Muted>
                //                     <h6>共創イベント</h6>
                //                   </Muted>
                //                   <p className={classes.description}>
                //                     Don{"'"}t be scared of the truth because we
                //                     need to restart the human foundation in
                //                     truth...
                //                   </p>
                //                 </CardBody>
                //               </GridItem>
                //             </GridContainer>
                //           </Card>
                //         </GridItem>
                //         <GridItem
                //           xs={12}
                //           sm={12}
                //           md={5}
                //           className={classes.gridItem}
                //         >
                //           <Card profile plain className={classes.card}>
                //             <GridContainer>
                //               <GridItem xs={12} sm={12} md={5}>
                //                 <CardHeader image plain>
                //                   <a href="#pablo">
                //                     <img src={marc} alt="..." />
                //                   </a>
                //                   <div
                //                     className={classes.coloredShadow}
                //                     style={{
                //                       backgroundImage: "url(" + marc + ")",
                //                       opacity: "1"
                //                     }}
                //                   />
                //                 </CardHeader>
                //               </GridItem>
                //               <GridItem xs={12} sm={12} md={7}>
                //                 <CardBody plain>
                //                   <h4 className={classes.cardTitle}>
                //                     日本ゲーミフィケーション協会
                //                   </h4>
                //                   <Muted>
                //                     <h6>DESIGNER</h6>
                //                   </Muted>
                //                   <p className={classes.description}>
                //                     Don{"'"}t be scared of the truth because we
                //                     need to restart the human foundation in
                //                     truth...
                //                   </p>
                //                 </CardBody>
                //               </GridItem>
                //             </GridContainer>
                //           </Card>
                //         </GridItem>
                //       </GridContainer>
                //     </div>
                //   )
                // },
                {
                  tabButton: "事業形態",
                  tabIcon: Camera,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        sm={12}
                        md={7}
                        className={classes.gridItem}
                      >
                        <h4 className={classes.title}>事業形態</h4>
                        <GridContainer className={classes.collections}>
                          <Table
                            tableData={[
                              ["名称", "ANOTHER PRODUCT (略称：アナプロ)"],
                              ["事業形態", "個人事務所"],
                              ["事業内容", "クリエイティブ人材の育成、デザインエンジニア育成"],
                              ["対象地域", "全国出張も対応可"],
                              ["設立", "2018年4月"],
                              ["所在地", "埼玉県鶴ヶ島市"],
                              ["営業時間", "平日9:00～18:00 　土・日祝日休み"],
                            ]}
                            customCellClasses={[
                              classes.textRight,
                              classes.textRight,
                              classes.textRight
                            ]}
                            customClassesForCells={[0, 4, 5]}
                            customHeadCellClasses={[
                              classes.textCenter,
                              classes.textRight,
                              classes.textRight
                            ]}
                            customHeadClassesForCells={[0, 4, 5]}
                          />
                          <p>
                            ※研修等の実施については曜日を問わず対応しております。
                          </p>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  )
                }
              ]}
            />
          </div>
          <Clearfix />
        </div>
      </div>
    </div>
  );
}
