import {
  container,
  title,
  mlAuto,
  blackColor,
  grayColor,
  whiteColor,
  sectionDark,
  section,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";

// const reusableStylesForImgs = {
//   position: "absolute",
//   boxShadow:
//     "0 8px 10px 1px rgba(" +
//     hexToRgb(blackColor) +
//     ", 0.14), 0 3px 14px 2px rgba(" +
//     hexToRgb(blackColor) +
//     ", 0.12), 0 5px 5px -3px rgba(" +
//     hexToRgb(blackColor) +
//     ", 0.2)"
// };

const featureSectionStyle = theme => ({
  // "@keyframes Floatingx": {
  //   from: { "-webkit-transform": "translate(50px, 0px)" },
  //   "65%": { "-webkit-transform": "translate(0, 0)" },
  //   to: { "-webkit-transform": "translate(50px, -0px)" }
  // },
  // "@keyframes Floatingy": {
  //   from: { "-webkit-transform": "translate(0, 0px)" },
  //   "65%": { "-webkit-transform": "translate(50px, 0)" },
  //   to: { "webkit-transform": "translate(0, -0px)" }
  // },
  container,
  mlAuto,
  sectionDark,
  title: {
    ...title,
    marginBottom: "30px", // 5->60
    marginTop: "5px", //60->5
    "& + $description": {
      marginTop: "10px", //5->10
      marginBottom: "5px" //30->5
    }
  },
  section: {
    ...section,
    padding: "0px 0px",
    "& h4$description": {
      fontSize: "1.5em",
      color: grayColor[1]
    }
  },
  sectionGray: {
    ...section,
    padding: "0px 0px",
    "& h4$description": {
      fontSize: "1.5em",
      color: grayColor[1]
    },
    background: grayColor[19] //11 19
  },
  description: {
    color: blackColor, //grayColor[1], //0->4
    fontSize: "15px", //add
    lineHeight: "2em" //add
  },
  // imageContainer: {
  //   height: "400px", //560->400
  //   position: "relative",
  //   marginBottom: "80px",
  //   marginTop: "80px",
  //   left: "50px" //add
  // },
  // imageContainer2: {
  //   height: "400px", //560->400
  //   position: "relative",
  //   marginBottom: "80px",
  //   marginTop: "80px",
  //   right: "50px" //add
  // },
  features: {
    marginBottom: "40px",
    marginTop: "40px",
    padding: "80px 0",
    "& $imageContainer": {
      maxWidth: "none",
      margin: "10px auto" //0px->
    }
  },
  imageContainer: {
    "& img": {
      width: "100%",
    }
  },
  infoArea: {
    maxWidth: "none",
    margin: "0 auto",
    padding: "10px 0 0px",
    fontSize: "15px", //add
    lineHeight: "2em", //add
    color: grayColor[1]
  },
  // componentsMacbook: {
  //   [theme.breakpoints.down("sm")]: {
  //     maxWidth: "850px!important",
  //     maxHeight: "480px!important",
  //     marginTop: "12vh",
  //     left: "-12px"
  //   },
  //   [theme.breakpoints.down(768)]: {
  //     maxWidth: "350px!important",
  //     maxHeight: "250px!important",
  //     marginTop: "12vh",
  //     left: "-12px"
  //   },
  //   width: "auto",
  //   left: "-100px",
  //   top: 0,
  //   height: "100%",
  //   position: "absolute"
  // },
  // shoppingCart: {
  //   [theme.breakpoints.down("md")]: {
  //     display: "none"
  //   },
  //   ...reusableStylesForImgs,
  //   left: "-13%",
  //   top: "27%",
  //   height: "175px",
  //   borderRadius: "2px",
  //   WebkitAnimation: "Floatingx 14s ease-in-out infinite",
  //   float: "left"
  // },
  // shareButton: {
  //   [theme.breakpoints.down("md")]: {
  //     top: "12%"
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     top: "7%"
  //   },
  //   ...reusableStylesForImgs,
  //   left: "3%",
  //   top: "-3%",
  //   height: "25px",
  //   WebkitAnimation: "Floatingy 11s ease-in-out infinite"
  // },
  // cardImage: {
  //   [theme.breakpoints.down("md")]: {
  //     top: "-2%",
  //     left: "65%"
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     top: "-2%"
  //   },
  //   ...reusableStylesForImgs,
  //   left: "35%",
  //   top: "2%",
  //   height: "125px",
  //   borderRadius: "6px",
  //   WebkitAnimation: "Floatingy 18s ease-in-out infinite"
  // },
  // twitterImage: {
  //   [theme.breakpoints.down("md")]: {
  //     display: "none"
  //   },
  //   ...reusableStylesForImgs,
  //   left: "90%",
  //   top: "11%",
  //   height: "90px",
  //   borderRadius: "2px",
  //   WebkitAnimation: "Floatingy 10s ease-in-out infinite"
  // },
  // iconsImage: {
  //   [theme.breakpoints.down("md")]: {
  //     left: "47%",
  //     top: "37%"
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     left: "-7%",
  //     top: "37%"
  //   },
  //   ...reusableStylesForImgs,
  //   left: "70%",
  //   top: "67%",
  //   height: "40px",
  //   WebkitAnimation: "Floatingx 16s ease-in-out infinite"
  // },
  // repostImage: {
  //   [theme.breakpoints.down("md")]: {
  //     top: "54%"
  //   },
  //   ...reusableStylesForImgs,
  //   left: "0%",
  //   top: "68%",
  //   height: "25px",
  //   borderRadius: "3px",
  //   WebkitAnimation: "Floatingy 15s ease-in-out infinite"
  // }
});

export default featureSectionStyle;
