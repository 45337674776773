/*eslint-disable*/
import React from "react";
// import emailjs from 'emailjs-com';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
// import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
// import Phone from "@material-ui/icons/Phone";
// import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";

// sections of this Page
import SectionContact from "./Sections/SectionContact.js";

import contactUsStyle from "assets/jss/material-kit-pro-react/anopro/contactUsStyle.js";

const useStyles = makeStyles(contactUsStyle);

export default function ContactPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax
        image={require("assets/img/anopro/contact.jpeg")}
        // filter="dark"
        className={classes.parallax}
      />
      <div className={classNames(classes.main)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>お問い合わせフォーム</h2>

            <GridContainer>
              <GridItem md={6} sm={6}>
                <p>
                  下記の入力画面に必要事項をご記入いただき、入力画面の最後にある「送信する」ボタンよりお問合せください。２日営業日以内に折り返しご連絡いたします。※全ての項目が必須です。
                  <br />
                  <br />
                </p>
                <SectionContact />
                {/* <form onSubmit={sendEmail}>
                  <CustomInput
                    name="from_inc"
                    labelText="ご所属"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    name="from_name"
                    labelText="お名前"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    name="from_name"
                    labelText="フリガナ"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    name="user_email"
                    labelText="Eメールアドレス"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    name="user_phone"
                    labelText="お電話"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    name="message_html"
                    labelText="お問い合わせ内容"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 10
                    }}
                  />
                  <div className={classes.textCenter}>
                    <Button color="primary" round type="submit">
                      送信する
                    </Button>
                  </div>
                </form> */}
              </GridItem>
              <GridItem md={4} sm={4} className={classes.mlAuto}>
                <InfoArea
                  className={classes.info}
                  title="Find us at the office"
                  description={
                    <p>
                      アナプロ
                      <br /> 営業時間：月～金
                      <br />9:00-18:00
                      <br />  <br />
                      〒350-2203
                      <br />
                      埼玉県鶴ヶ島市上広谷
                      <br/>793番地28
                      <br/>ヴェルデ若葉207{" "}

                    </p>
                  }
                  icon={PinDrop}
                  iconColor="primary"
                />
                {/* <InfoArea
                  className={classes.info}
                  title="Give us a ring"
                  description={
                    <p>
                      アナプロ(代表)
                      <br />
                      080-5405-2760
                      <br /> 営業時間：月～金
                      <br />9:00-18:00
                    </p>
                  }
                  icon={Phone}
                  iconColor="primary"
                /> */}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
