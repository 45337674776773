import React from 'react';
import emailjs from 'emailjs-com';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

// const useStyles = makeStyles(contactUsStyle);
// const classes = useStyles();

class SectionContact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      from_affiliation: '',
      from_name: '',
      from_kana: '',
      user_email: '',
      user_phone: '',
      message_html: '',
      infomation: '※ボタンを押すと直ちに送信されます',
    };
    this.sendEmail = this.sendEmail.bind(this);
    this.doChange = this.doChange.bind(this);
  }

  // validateEmail() {
  //   let errors = {}
  //   let formIsValid = true

  //   if (!this.state.affiliation) {
  //     errors.affiliation = 'ご所属をご記入ください'
  //     formIsValid = false
  //   }

  //   if (!this.state.name) {
  //     errors.name = 'お名前をご記入ください'
  //     formIsValid = false
  //   }

  //   if (!this.state.kana) {
  //     errors.kana = 'フリガナをご記入ください'
  //     formIsValid = false
  //   }

  //   if (!this.state.phone) {
  //     errors.phone = '連絡先をご記入ください'
  //     formIsValid = false
  //   }

  //   if (!this.state.message) {
  //     errors.message = 'お問い合わせ内容をご記入ください'
  //     formIsValid = false
  //   }

  //   let pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w)*(\.\w{2.3})+$/
  //   if (!pattern.test(this.state.email)) {
  //     errors.email = 'Eメールアドレスをご記入ください'
  //     formIsValid = false
  //   }

  //   this.setState({
  //     errors: errors
  //   })
  //   return formIsValid
  // }

  doChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value })
  }

  sendEmail(e) {
    e.preventDefault();

    // if (!this.validateEmail()) {
    //   retrun
    // }

    emailjs.sendForm('gmail', 'template_7npgyNtK', e.target, 'user_9awwzLlwD2KDVBSCDYD7N')
      .then((result) => {
        this.setState({
          from_affiliation: '',
          from_name: '',
          from_kana: '',
          user_email: '',
          user_phone: '',
          message_html: '',
          infomation: 'お問い合わせありがとうございます。承りました。'
        });
        console.log(result.text);
      }, (error) => {
        this.setState({
          infomation: '送信できませんでした。お手数ですが sekiguchi@anopro.net まで直接お問い合わせください。'
        });
        console.log(error.text);
      });
  }

  render() {
    return (
      <div>
        <form onSubmit={this.sendEmail}>
          <CustomInput
            name="from_affiliation"
            labelText="ご所属(会社・組織)"
            id="float"
            required
            value={this.state.from_affiliation}
            onChange = {this.doChange}
            formControlProps={{
              fullWidth: true
            }}
          />
          <CustomInput
            name="from_name"
            labelText="お名前"
            id="float"
            required
            value={this.state.from_name}
            onChange = {this.doChange}
            formControlProps={{
              fullWidth: true
            }}
          />
          <CustomInput
            name="from_kana"
            labelText="フリガナ"
            id="float"
            required
            value={this.state.from_kana}
            onChange = {this.doChange}
            formControlProps={{
              fullWidth: true
            }}
          />
          <CustomInput
            name="user_email"
            labelText="Eメールアドレス"
            id="float"
            required
            value={this.state.user_email}
            onChange = {this.doChange}
            formControlProps={{
              fullWidth: true
            }}
          />
          <CustomInput
            name="user_phone"
            labelText="お電話"
            id="float"
            required
            value={this.state.user_phone}
            onChange = {this.doChange}
            formControlProps={{
              fullWidth: true
            }}
          />
          <CustomInput
            name="message_html"
            labelText="お問い合わせ内容"
            id="float"
            required
            value={this.state.message_html}
            onChange = {this.doChange}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              multiline: true,
              rows: 10
            }}
          />
          <Button color="primary" type="submit">
            送信する
          </Button>
        </form>
        <h4>{this.state.infomation}</h4>
      </div>
    )
  }
}
export default SectionContact