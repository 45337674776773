import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui icons
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import priceSectionStyle from "assets/jss/material-kit-pro-react/anopro/priceSectionStyle.js";

const useStyles = makeStyles(priceSectionStyle);

export default function SectionPrice() {
  const classes = useStyles();
  return (
    <div className={"cd-section"} id="offer">
      <div className={classes.section}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={5}
              sm={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <div>
                <h4 className={classes.description}>― CONTACT ―</h4>
                <h2 className={classes.title}>お申込みについて</h2>
                <h5 className={classes.description}>
                  通常、1.5～2日間の集合研修形式で、御社に訪問させていただく形で実施します。一度にまとまった時間が取れない、テーマを相談したいなどの対応も可能です。まずは、お気軽にお問い合わせフォームからご相談下さい。
              </h5>
              </div>
              <Link to="/contact">
                <Button color="primary">
                  お問い合わせフォームへ
                </Button>
              </Link>
            </GridItem>
            <GridItem md={6} sm={12} className={classes.mlAuto}>
              <GridContainer>
                <GridItem md={6} sm={6}>
                  <Card className={classNames(classes.card, classes.cardPricing)}>
                    <CardBody>
                      <h3 className={classes.cardTitle}>スタンダード</h3>
                      <ul>
                        <li>
                          <b>受講料</b>　<b>400,000円～</b>
                        </li>
                        <li>
                          <b>定員</b>　<b>最大20名</b>
                        </li>
                        <li>
                          <Check
                            className={classNames(
                              classes.cardIcons,
                              classes.successColor
                            )}
                          />{" "}1日ごとに開催日調整可
                      </li>
                        <li>
                          <Check
                            className={classNames(
                              classes.cardIcons,
                              classes.successColor
                            )}
                          />{" "}実習テーマの変更
                      </li>
                      </ul>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem md={6} sm={6}>
                  <Card className={classNames(classes.card, classes.cardPricing)}>
                    <CardBody>
                      <h3 className={classes.cardTitle}>カスタマイズ</h3>
                      <ul>
                        <li>
                          <b>受講料</b>　 <b>600,000円～</b>
                        </li>
                        <li>
                          <b>定員</b>　<b>最大20名</b>
                        </li>
                        <li>
                          <Check
                            className={classNames(
                              classes.cardIcons,
                              classes.successColor
                            )}
                          />{" "}実施日数など調整可
                      </li>
                        <li>
                          <Check
                            className={classNames(
                              classes.cardIcons,
                              classes.successColor
                            )}
                          />{" "}実習テーマの変更
                      </li>
                        <li>
                          <Check
                            className={classNames(
                              classes.cardIcons,
                              classes.successColor
                            )}
                          />{" "}研修コンテンツ新規作成
                      </li>
                        <li>
                          <Check
                            className={classNames(
                              classes.cardIcons,
                              classes.successColor
                            )}
                          />{" "}プロジェクト伴走
                      </li>
                      </ul>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
