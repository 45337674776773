import {
  container,
  title,
  mlAuto,
  mrAuto,
  section,
  grayColor,
  cardTitle,
  blackColor
} from "assets/jss/material-kit-pro-react.js";

const aboutSectionStyle = {
  container,
  cardTitle,
  mlAuto,
  mrAuto,
  title: {
    ...title,
    textAlign: "center",
    marginBottom: "30px", // 5->30
    marginTop: "5px", //60->5
    "& + $description": {
      marginTop: "10px", //5->10
      marginBottom: "5px" //30->5
    }
  },
  section: {
    ...section,
    padding: "70px 0px",
    "& h4$description": {
      fontSize: "1.5em",
      textAlign: "center",
      color: grayColor[1] //0->4
    }
  },
  features: {
    textAlign: "center !important",
    paddingTop: "30px",
    paddingBottom: "0px",
    "& p": {
      fontSize: "16px", //16->
      lineHeight: "1.6em" //1.6->
    }
  },
  description: {
    color: blackColor, //grayColor[1], //0->4
    fontSize: "15px", //add
    lineHeight: "2em" //add
  },
  description2: {
    color: grayColor[4], //0->4
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "15px", //16->
    lineHeight: "1.8em" //1.6->
  },
  imgicon:{
    padding: "10px 100px 10px 100px"
  },
  imgicon2:{
    padding: "0px 50px 0px 50px"
  },
  cardTitle2:{
    "&, & a": {
      ...title,
      marginTop: "0rem",
      marginBottom: "0.75rem",
      minHeight: "auto"
    }
  },
  space50:{
    marginTop: "50px",
  },
};

export default aboutSectionStyle;
