/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Mail from "@material-ui/icons/Mail";
import Favorite from "@material-ui/icons/Favorite";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Footer from "components/Footer/Footer.js";

import styles from "assets/jss/material-kit-pro-react/anopro/footerStyle.js";

const useStyles = makeStyles(styles);

export default function SectionFooter() {
  const classes = useStyles();
  return (
    <div>
      <Footer
        theme="dark"
        content={
          <div>
            {/* <ul className={classes.socialButtons}>
              <li>
                <Button justIcon simple href="#pablo" color="twitter">
                  <i className="fab fa-twitter" />
                </Button>
              </li>
              <li>
                <Button justIcon simple href="#pablo" color="facebook">
                  <i className="fab fa-facebook-square" />
                </Button>
              </li>
              <li>
                <Button justIcon simple href="#pablo" color="dribbble">
                  <i className="fab fa-dribbble" />
                </Button>
              </li>
              <li>
                <Button justIcon simple href="#pablo" color="google">
                  <i className="fab fa-google-plus-g" />
                </Button>
              </li>
              <li>
                <Button justIcon simple href="#pablo" color="youtube">
                  <i className="fab fa-youtube" />
                </Button>
              </li>
            </ul> */}
            <div
              className={classNames(classes.pullCenter, classes.copyRight)}
            >
              Copyright &copy; {1900 + new Date().getYear()}{" "}
              アナプロ{" "}
              All Rights Reserved.
              </div>
          </div>
        }
      >
        <div className={classes.footer}>
          <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
              <h5> クリエイティブ人材の育成</h5>
              <p>
                アナプロでは新しいステージにチャレンジする企業・組織・ビジネスパーソンにクリエイティブな学びの機会を作っています。
              </p>
            </GridItem>
            {/* <GridItem xs={12} sm={3} md={3}>
              <h5>アナプロについて</h5>
              <ul className={classes.linksVertical}>
                <li>
                  <a href="#pablo">はじめての方</a>
                </li>
                <li>
                  <a href="#pablo">コラム-すべて</a>
                </li>
                <li>
                  <a href="#pablo">コラム-プロセス</a>
                </li>
              </ul>
            </GridItem> */}
            <GridItem xs={12} sm={3} md={3}>
              <h5>サービス</h5>
              <ul className={classes.linksVertical}>
                <li>
                  <Link to="/">デザインマインドブートキャンプ</Link>
                </li>
                {/* <li>
                  <a href="#pablo">デザインスキルオンライン講座</a>
                </li> */}
              </ul>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <h5>概要</h5>
              <ul className={classes.linksVertical}>
                <li>
                  <Link to="/contact">コンタクト</Link>
                  {/* <a href="#pablo">コンタクト</a> */}
                </li>
                <li>
                  <a href="/profile">プロフィール</a>
                </li>
                <li>
                  <Link to="/policy">個人情報取り扱いについて</Link>
                  {/* <a href="#pablo">個人情報取り扱いについて</a> */}
                </li>
              </ul>
            </GridItem>
          </GridContainer>
        </div>
      </Footer>
    </div>
  );
}

// http://eepurl.com/gOPa1L
