/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import policyPageStyle from "assets/jss/material-kit-pro-react/anopro/policyPageStyle.js";

const useStyles = makeStyles(policyPageStyle);

export default function PolicyPage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div>
      <Parallax
        image={require("assets/img/anopro/hand.jpeg")}
        filter="dark"
        className={classes.parallax}
      />
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>個人情報保護方針について</h2>
            </GridItem>
          </GridContainer>
            <div className={classNames(classes.description)}>
              <h5> 
                アナプロでは、発行する刊行物およびウェブサイト等をご利用になる皆様から提供を受けた個人情報（氏名・住所・電話番号等、個人を識別できる情報）を取り扱っています。これらの情報は、「個人情報の保護に関する法律」及びその関連法令（以下「法」といいます）及び法に基づいた社内規範を遵守するとともに、細心の注意をはらって適正な管理に努めるべきものであり、アナプロでは以下に個人情報に関する取扱い方針を定め、その実現に努めます。個人情報保護規範を定め、遵守し、皆様の個人情報を適正に管理します。
              </h5>
              <ol className={classes.listOnstyled}>
                <li>
                  個人情報管理責任者を置き、個人情報管理体制を実行あるものとします。
                </li>
                <li>
                個人情報の収集、利用にあたっては目的を明確にし、その範囲内で適正な方法により収集、利用を行います。
                </li>
                <li>
                個人情報は、法に基づき開示が義務付けられている場合を除き、皆様の事前の承諾なしに第三者に開示や提供をいたしません。
                </li>
                <li>
                個人情報の保管は適正な方法で必要な期間行い、その後遺漏なく廃棄いたします。
                </li>
                <li>
                個人情報の管理等を第三者に委託する場合は、委託会社に対し、保護規範に準拠して管理されるよう、適切な措置をとるものとします。
                </li>
                <li>
                ご提供いただいた個人情報に関し、内容の開示や訂正、利用停止、消去等のお申し出があった場合は、法に定める場合を除き、ご本人確認等必要な手続きの上、速やかに対処いたします。
                </li>
                <li>
                  本個人情報に関する公表事項は下記のとおりであります。
                </li>
              </ol>
            </div>
            {/* 個人情報保護に関する法律」に基づく公表事項 */}
            <div className={classNames(classes.description)}>
              <h3>
              「個人情報保護に関する法律」に基づく公表事項
              </h3>
              <h4>
              個人情報の利用目的
              </h4>
              <h5> 
                お客様の個人情報は、以下の目的で利用いたします。
              </h5>
              <ol className={classes.listOnstyled}>
                <li>ご購入・ご登録いただいたセミナー、コンサルティング、商品…など、各種サービスのお申し込みの確認やお届けをするため。
                  <ol className={classes.listOnstyled}>
                    <li>イベントやセミナー等のお申し込みの確認や参加証等をお届けするため。</li>
                    <li>書籍、その他の商品等のご購入の確認やお届けするため。</li>
                    <li>各種商品、サービスのご請求、お支払いとその確認をするため。</li>
                    <li>ご応募いただいた懸賞等に対する景品等をお送りするため。</li>
                    <li>アンケート等に対して謝礼等をお送りするため。</li>
                  </ol>
                </li>
                <li>提供している商品やサービスに関連した情報等をお届けするため。
                  <ol className={classes.listOnstyled}>
                    <li>各種セミナー、イベントへのご参加の依頼やその結果等を報告するため。</li>
                    <li>書籍、各種会員制サービス等のご案内をお届けするため。</li>
                  </ol>
                </li>
                <li>商品・サービスの提供改善や、新たなサービスを開発するため。
                  <ol className={classes.listOnstyled}>
                    <li>セミナー、書籍、その他のお客様がよりご満足いただけるよう改良するため。</li>
                    <li>利用状況を把握し、サービス改善や新サービスの開発に役立てるため。</li>
                    <li>お客様が注文された情報や商品等に関する満足度を調査するため。</li>
                    <li>利用状況を把握し、サービス改善や新サービスの開発に役立てるため。</li>
                  </ol>
                </li>
              </ol>
          </div>
          {/* 個人情報の第三者提供について */}
          <div className={classNames(classes.description)}>
            <h3>
              個人情報の第三者提供について
            </h3>
            <h5> 
              アナプロは、法令に定める場合を除き、個人データを事前に本人の同意を得ることなく、第三者に提供しません。ただし、次のいずれかに該当する場合は、第三者に提供する場合があります。
            </h5>
            <ol className={classes.listOnstyled}>
                <li>法令に基づく場合</li>
                <li>
                人の生命、身体又は財産の保護のため必要であるが、ご本人の同意を得ることが困難な場合。
                </li>
                <li>
                公衆衛生の向上や児童の育成のために必要であるが、ご本人の同意を得ることが困難な場合。
                </li>
                <li>
                国の機関若しくは地方公共団体又はその依託を受けた者が、法令の定める事務を遂行することに対して協力する場合、本人の同意を得ることにより当該事務の遂行に支障をきたすおそれのある場合。
                </li>
                <li>
                個人を識別し得ない「統計データ」として利用する場合。
                </li>
                <li>
                利用目的の達成に必要な範囲内で個人データの取扱いを第三者に委託する場合。なお、個人データの取扱いを委託する委託先は、当社が信頼するに足ると判断した場合に限り、個人データの安全管理が図られるよう、当社と秘密保持契約を締結し、必要かつ適切な管理を行う。
                </li>
                <li>
                お客様が当社経由で当社以外の企業、団体に対して情報提供、サービスの提供、商品の注文応募、接触、仲介をご依頼いただいた場合や、それらの企業団体が関係する展示会、セミナーの申し込みをされた場合。また広告掲載会社等への資料請求の仲介を当社に依頼された場合。
                </li>
                <li>
                有料サービスのご利用や、商品注文等に伴うクレジットカード等の決済手続きに際して、ユーザーの銀行口座やクレジットカード等の正当性を金融機関、カード会社等に確認する場合。
                </li>
                <li>
                あらかじめ本人に通知してある利用目的が第三者への個人データの提供で、提供される個人データの項目や提供される手段又は方法、ならびに本人の求めに応じて個人データの提供を中止することについて通知してある場合。
                </li>
              </ol>
          </div>
          {/* 個人情報の開示、訂正、利用停止、消去等について */}
          <div className={classNames(classes.description)}>
            <h3>
              個人情報の開示、訂正、利用停止、消去等について
            </h3>
            <h5> 
              お客様ご本人が自己の個人情報について開示、内容訂正、利用停止、消去等を請求する権利を有していることを確認し、これらの請求がある場合には速やかに対応いたします。ただし、次の場合には請求に応じることはできません。
            </h5>
            <ol className={classes.listOnstyled}>
                <li>請求事項につき、当社が直接実施する権限を有していない個人情報。</li>
                <li>6ヶ月以内に消去されるものとして管理されている個人情報。</li>
                <li>個人情報の存否の判明が、ご本人や他者へ生命・身体・財産等に危険・危害を及ぼす恐れがあると当社が判断した場合。</li>
                <li>個人情報の存否の判明が、違法または不法な行為を誘発・助長する恐れがあると当社が判断した場合。</li>
              </ol>
            <h5> 
              なお、ご請求に際しては、ご請求がお客様ご本人によるものであることを当社が確認する手続きにご協力いただくこと、また当該個人情報の抽出およびこれに伴う適切な処置対応、説明の準備には相当の日数を要することをあらかじめご了解ください。また、ご請求内容によりましては実費を勘案した適切な手数料のご負担をお願いすることがあることをあらかじめご了解ください。
            </h5>
          </div>
          {/* 個人情報保護に関する方針の公表と変更について */}
          <div className={classNames(classes.description)}>
            <h3>
              個人情報保護に関する方針の公表と変更について
            </h3>
            <h5> 
              本方針は本ウェブサイトにおいて公表いたします。方針は、必要に応じて変更することがありますが、これにつきましても上記の手段にて公表するほか、変更内容に応じて必要と思われる合理的かつ適切な方法により、公表、ご本人への通知等を行います。
            </h5>
          </div>
          {/* お問い合わせ */}
          <div className={classNames(classes.description)}>
            <h3>
              お問い合わせ
            </h3>
            <h5> 
              個人情報の取扱に関するお問い合せは下記までご連絡ください。
            </h5>
            <Link to="/contact">お問い合わせはこちら（フォームへ）</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
